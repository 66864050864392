import consumer from "./consumer"

consumer.subscriptions.create("ApplicationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    $('#applications_import_status').text('finished');
  },

  /**
   * Increase the count by one each time a message is recieved
   * from worker, this means one batch has finished processing.
   *
   * Update frontend with progress - allows user to see progress of processing jobs
   * without need to use a framework like React/Vue.
   * @param {*} data message from backend worker
   */
  received(data) {
    // Called when there's incoming data on the websocket for this channel
    $('#applicant_import_progress_container').show();

    let local_storage_object = JSON.parse(localStorage.getItem('application_import_progress'));
    let current_date_time = new Date();
    let expiry_date_time = new Date(local_storage_object?.expiry);

    // Check if it's expired
    if ( expiry_date_time < current_date_time ) {
      localStorage.removeItem('application_import_progress');
    }

    local_storage_object = JSON.parse(localStorage.getItem('application_import_progress'));
    let current_state = parseInt(local_storage_object?.progress) || 0;
    current_state += 1;

    // Set expiry time
    current_date_time.setMinutes( current_date_time.getMinutes() + 1 );

    localStorage.setItem('application_import_progress', JSON.stringify({ progress: current_state, expiry: current_date_time }));

    let progress_as_percentage = Math.floor((current_state / data["lines_divded_by_chunks"]) * 100);
    $('#applications_import_message').text('Applications Processing... ' + progress_as_percentage + '%');

    // If we have processed the name number of chunks as the total then we are finished
    if(current_state == data["lines_divded_by_chunks"]){
      $('#applicant_import_progress_container').hide();
      localStorage.removeItem('application_import_progress');

      // Refresh the page to see updated data
      location.reload();
    }
  }
});
