'use strict';

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"


/* ===== Enable Bootstrap Popover (on element  ====== */

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})

/* ==== Enable Bootstrap Alert ====== */
var alertList = document.querySelectorAll('.alert')
alertList.forEach(function (alert) {
    new bootstrap.Alert(alert)
});

document.addEventListener("turbolinks:load", function() {

    /* Date Pickers */
    flatpickr("#from_created_at_datepicker", {})
    flatpickr("#to_created_at_datepicker", {})

    /* ===== Responsive Sidepanel ====== */
    const sidePanelToggler = document.getElementById('sidepanel-toggler');
    const sidePanel = document.getElementById('app-sidepanel');
    const sidePanelDrop = document.getElementById('sidepanel-drop');
    const sidePanelClose = document.getElementById('sidepanel-close');

    window.addEventListener('load', function () {
        responsiveSidePanel();
    });

    window.addEventListener('resize', function () {
        responsiveSidePanel();
    });


    function responsiveSidePanel() {
        let w = window.innerWidth;
        if (w >= 1200) {
            // if larger
            //console.log('larger');
            sidePanel.classList.remove('sidepanel-hidden');
            sidePanel.classList.add('sidepanel-visible');

        } else {
            // if smaller
            //console.log('smaller');
            sidePanel.classList.remove('sidepanel-visible');
            sidePanel.classList.add('sidepanel-hidden');
        }
    };

    sidePanelToggler.addEventListener('click', () => {
        if (sidePanel.classList.contains('sidepanel-visible')) {
            console.log('visible');
            sidePanel.classList.remove('sidepanel-visible');
            sidePanel.classList.add('sidepanel-hidden');

        } else {
            console.log('hidden');
            sidePanel.classList.remove('sidepanel-hidden');
            sidePanel.classList.add('sidepanel-visible');
        }
    });

    sidePanelClose.addEventListener('click', (e) => {
        e.preventDefault();
        sidePanelToggler.click();
    });

    sidePanelDrop.addEventListener('click', (e) => {
        sidePanelToggler.click();
    });
});
